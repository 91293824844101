//--------取引先一覧 works_client
.page-works-client{
  line-height: 2;
  // .contents{
  //   width: $width-contents-pc;
  //   max-width: calc(100% - 60px);
  //   @include mq() {
  //     width: auto;
  //     max-width: 100%;
  //   }
  // }

  .works-client{
    &-outline{
      // border-top: 1px solid #ccc;
      // padding-top: 60px;
      margin-bottom: 120px;
      &-inner{
        width: $width-contents-pc;
        max-width: calc(100% - 60px);
        margin: 0 auto;
        &-title{
          &-2{
            margin-top: 24px;
          }
        }
        &-other{
          // font-size: 16px;
          // font-weight: 600;
          margin: 0 20px 15px;
        }
        tr{
          th{
            width: 260px;
          }
          td{
            width: 700px;
            // ul{
            //   ::before{
            //     content: "・";
            //   }
            // }
          }
        }
      }
      h2{
        font-size: 38px;
        font-weight: 600;
        margin: 0 20px 15px;
        &:last-of-type{
          margin: 60px 20px 15px;
        }
      }
      h3{
        font-size: 1.125em;
        font-weight: 700;
      }
      &-bottom-text {
        font-size: 16px;
        line-height: 2;
        margin-top: 1em;
        padding-left: 1em;
        text-indent: -1em;
        &::before {
          content: "※";
        }
      }
    }
    @include mq() {
      &-outline{
        font-size: 14px;
        h2{
          font-size: 24px;
        }
        &-inner{
          width: auto;
          max-width: 100%;
          tr{
            th{
              width: auto;
            }
            td{
              width: auto;
            }
          }
        }
        &-bottom-text {
          font-size: 14px;
        }
        .table + .works-client-outline-bottom-text {
          margin: 1em 20px 0;
        }
      }
    }
  }
  .pc-on{
    display: block;
    @include mq() {
      display: none;
    }
  }
  .sp-on{
    display: none;
    @media (max-width: 500px) {
      display: block;
    }
  }
}