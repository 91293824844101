
.service {
  &-header {
    margin: 110px auto;
    text-align: center;
    .en {
      font-size: 50px;
      font-family: $font-en;
      margin-bottom: 20px;
    }
    .ja {
      font-size: 64px;
      font-weight: 600;
    }
  }
  &-section {
    &-header {
      margin-bottom: 80px;
      text-align: center;
      font-size: 38px;
      font-weight: 600;
      font-family: $font-en;
      line-height: 1.4;
      &-text {
        font-size: 16px;
        line-height: 2;
        text-align: center;
        margin-bottom: 50px;
      }
    }
  }
  &-index {
    &-wrapper {
      display: flex;
      align-items: center;
      margin-bottom: 90px;
      .page-service & {
        margin-bottom: 0;
      }
    }
    &-block {
      width: 33.3%;
      position: relative;
      &-integram, &-accelem, &-seo{
        padding: 10px 0;
        @include mq(sm) {
          padding: 0;
        }
      }
      &-integram{
        @include mq(sm) {
          margin-top: 60px;
        }
      }
      &-row1 {
        padding-top: 20px;
        margin: 0 10%;
      }
      &-row2 {
        padding: 40px 10%;
        transition: .3s;
      }
      &-title {
        display: inline-block;
        font-size: 20px;
        line-height: 1.6;
        position: relative;
        &-wrapper {
          margin-bottom: 20px;
          text-align: center;
        }
      }
      &-img {
        margin-bottom: 30px;
        svg {
          display: block;
          margin: 0 auto;
          width: 130px;
          height: 130px;
          transition: 0.3s;
        }
        &-integram{
          svg{
            width: 223px;
            height: 170px;
          }
        }
        &-accelem{
          svg{
            width: 245px;
            height: 170px;
          }
        }
        &-ecseo{
          svg{
            width: 197px;
            height: 170px;
          }
        }
      }
      &-content {
        font-size: 16px;
        line-height: 1.6;
        &-label {
          font-weight: 600;
          margin-bottom: 10px;
        }
      }
      &-list {
        &-item {
          font-size: 16px;
          line-height: 2;
          margin-bottom: 1.6px;
          &:last-child {
            margin-bottom: 0;
          }
          .icon {
            width: 18px;
            height: 18px;
            fill: #FFF;
            margin-right: 15px;
            position: relative;
            top: 2px;
          }
        }
      }
      &-strategy {
        .service-index-block {
          &-row2 {
            background: $color-blue;
            color: #FFF;
          }
          &-here {
            background: $color-blue;
            &::after {
              border-bottom: 16px solid $color-blue;
            }
          }
        }
        a {
          &:hover {
            .service-index-block {
              &-row2 {
                background: darken($color-blue, 5%);
              }
            }
          }
        }
      }
      &-creative {
        .service-index-block {
          &-row2 {
            background: $color-pink;
            color: #FFF;
          }
          &-here {
            background: $color-pink;
            &::after {
              border-bottom: 16px solid $color-pink;
            }
          }
        }
        a {
          &:hover {
            .service-index-block {
              &-row2 {
                background: darken($color-pink, 5%);
              }
            }
          }
        }
      }
      &-operation {
        .service-index-block {
          &-row2 {
            background: $color-yellow;
            color: #000;
          }
          &-here {
            color: #000;
            background: $color-yellow;
            &::after {
              border-bottom: 16px solid $color-yellow;
            }
          }
          &-list {
            &-item {
              .icon {
                fill: #000;
              }
            }
          }
        }
        a {
          &:hover {
            .service-index-block {
              &-row2 {
                background: darken($color-yellow, 5%);
              }
            }
          }
        }
      }
      &-here {
        position: absolute;
        top: -60px;
        left: -100px;
        display: flex;
        justify-content: center;
        align-items: center;
        color: #FFF;
        font-size: 20px;
        font-weight: 700;
        font-family: $font-en;
        width: 84px;
        height: 84px;
        border-radius: 50%;
        &::after {
          content: "";
          display: block;
          position: absolute;
          bottom: 10px;
          right: 0;
          border: 16px solid transparent;
          transform: rotate(20deg);
        }
      }
      a {
        display: block;
        &:hover {
          background: #FFF;
          text-decoration: none;
          .service-index-block-img {
            svg {
              transform: scale(1.2);
            }
          }
        }
      }
    }
  }
  &-contact {
    a {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      color: #FFF;
      background: #968852;
      height: 300px;
      text-align: center;
      margin-bottom: 120px;
      &:hover {
        background: darken(#968852, 5%);
        text-decoration: none;
      }
    }
    &-header {
      font-size: 20px;
      line-height: 1.6;
      margin-bottom: 20px;
    }
    &-main {
      font-size: 62px;
      font-weight: 600;
      font-family: $font-en;
    }
  }
  &-catch {
    margin-bottom: 160px;
    text-align: center;
    position: relative;
    &-bg {
      position: absolute;
      bottom: 0;
      right: -12vw;
      z-index: -1;
      svg {
        width: 30vw;
        height: 30vw;
      }
      @include mq(xlg) {
        top: -20vw;
        bottom: auto;
      }
      @include mq(smd) {
        top: -30vw;
        right: -18vw;
        svg {
          width: 40vw;
          height: 40vw;
        }
      }
      @include mq(sm) {
        top: -40vw;
        right: -24vw;
        svg {
          width: 50vw;
          height: 50vw;
        }
      }
    }
    &-header {
      font-size: 54px;
      font-family: $font-mincho;
      font-weight: 600;
      line-height: 1.6;
      letter-spacing: 0.1em;
      margin-bottom: 30px;
    }
    &-text {
      font-size: 16px;
      line-height: 2;
    }
  }
  &-strategy {
    &-header {
      display: flex;
      justify-content: center;
      margin: 110px auto;
      &-inner {
        padding: 40px 80px;
        background: $color-blue;
        color: #FFF;
        text-align: center;
      }
      &-title {
        font-size: 54px;
        font-family: $font-mincho;
        font-weight: 600;
        line-height: 1.2;
        letter-spacing: 0.1em;
        margin-bottom: 30px;
      }
      &-text {
        font-size: 16px;
        line-height: 2;
      }
    }
    &-text {
      font-size: 16px;
      line-height: 2;
      text-align: center;
    }
    &-section {
      margin-bottom: 170px;
    }
    &-flow {
      .service-section-header {
        margin-bottom: 40px;
      }
      svg {
        display: block;
        width: 1030px;
        max-width: 100%;
        height: auto;
        margin: 0 auto;
        tspan {
          font-family: $font-gothic;
          font-weight: 500;
        }
      }
      img {
        display: block;
        margin: 0 auto;
      }
    }
  }
  &-creative {
    &-text {
      font-size: 16px;
      line-height: 2;
      text-align: center;
    }
    &-section {
      margin-bottom: 170px;
    }
    &-figure {
      &-inner {
        display: flex;
        justify-content: space-between;
        width: $width-page-pc;
        margin: 0 auto;
      }
      &-block {
        text-align: center;
        &-label {
          font-size: 24px;
          font-weight: bold;
          line-height: 1.4;
          margin-bottom: 30px;
        }
        img {
          position: relative;
          width: 100%;
        }
        &-general {
          width: 480px;
          svg {
            width: 440px;
            height: 486px;
            display: block;
            margin: 0 auto;
            text {
              font-family: $font-gothic;
              font-weight: 500;
            }
          }
        }
        &-tryangle {
          width: 740px;
          svg {
            width: 740px;
            height: 710px;
            display: block;
            margin: 0 auto;
            text {
              font-family: $font-gothic;
              font-weight: 500;
            }
          }
        }
        &-img {
          width: 100%;
          height: 710px;
          display: flex;
          align-items: center;
          position: relative;
          img {
            width: 100%;
            display: block;
            margin: 0 auto;
          }
        }
      }
    }
    &-policy {
      &-inner {
        width: $width-page-pc;
        margin: 0 auto;
      }
      &-list {
        display: flex;
        justify-content: center;
        &-item {
          width: 400px;
          display: flex;
          flex-direction: column;
          align-items: center;
          margin: 0 40px;
          &-img {
            margin-bottom: 40px;
            .icon {
              width: 130px;
              height: 130px;
            }
          }
          &-title {
            font-size: 24px;
            font-weight: 600;
            line-height: 1.4;
            margin-bottom: 30px;
            text-align: center;
          }
          &-text {
            font-size: 16px;
            line-height: 2;
          }
        }
      }
    }
    &-works {
      &-list {
        display: flex;
        flex-wrap: wrap;
        padding-left: 40%;
        position: relative;
        &-item {
          width: 33.3%;
          flex-shrink: 0;
          &:first-child {
            width: 40%;
            position: absolute;
            top: 0;
            left: 0;
          }
          img {
            display: block;
            width: 100%;
          }
        }
      }
    }
    &-system {
      &-inner {
        width: $width-page-pc;
        margin: 0 auto;
      }
      &-list {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        &-wrapper {
          padding: 60px 120px 20px;
          background: #FFF;
        }
        &-item {
          margin: 0 30px 30px;
          display: flex;
          justify-content: center;
          align-items: center;
          img {
            width: 140px;
            display: block;
            margin: 0 auto;
          }
        }
        &-note {
          margin-bottom: 30px;
          font-size: 16px;
          line-height: 2;
          margin-left: 1em;
          text-indent: -1em;
          &::before {
            content: "※";
          }
        }
      }
      &-note {
        margin-top: 40px;
        &-list {
          &-item {
            font-size: 16px;
            line-height: 2;
            margin-left: 1em;
            text-indent: -1em;
            &::before {
              content: "※";
            }
            &-list {
              &-item {
                margin-left: 1em;
                text-indent: -1em;
                &::before {
                  content: "・";
                }
              }
            }
          }
        }
      }
    }
  }
  &-operation {
    &-compare {
      margin-bottom: 120px;
      &-inner {
        display: flex;
        justify-content: space-between;
        width: 1000px;
        margin: 0 auto;
      }
      &-block {
        &-wrapper {
          width: calc(50% - 20px);
        }
        text-align: center;
        padding: 40px 0 15px;
        background: #FFF;
        &-title {
          font-size: 20px;
          line-height: 1.6;
          margin-bottom: 50px;
          &-sub {
            font-size: 14px;
            margin-bottom: 30px;
          }
        }
        &-img {
          margin: 0 36px 30px;
          svg {
            text {
              font-size: 14px;
              font-family: $font-gothic;
            }
          }
        }
        &-feature {
          padding: 10px;
          &-list {
            &-item {
              display: flex;
              flex-direction: column;
              justify-content: center;
              align-items: center;
              height: 110px;
              padding: 12px 20px;
              margin-bottom: 15px;
              background: #F5F5F5;
              &:last-child {
                margin-bottom: 0;
              }
              &-text {
                &-sub {
                  font-size: 14px;
                  line-height: 1.6;
                }
                &-main {
                  font-size: 18px;
                  font-weight: 600;
                  line-height: 1.6;
                }
              }
            }
          }
        }
        &-bottom-text {
          font-size: 20px;
          font-weight: 600;
          line-height: 1.4;
          margin-top: 15px;
          text-align: center;
        }
        &-general {
          border: 10px solid #FFF;
          .service-operation-compare-block {
            &-feature {
              &-list {
                &-item {
                  &-text {
                    &-sub {
                      color: #000;
                    }
                    &-main {
                      color: $color-blue;
                    }
                  }
                }
              }
            }
          }
        }
        &-tryangle {
          border: 10px solid $color-yellow;
          .service-operation-compare-block {
            &-title {
              margin-bottom: 5px;
            }
            &-feature {
              &-list {
                &-item {
                  &-text {
                    &-sub {
                      color: #000;
                    }
                    &-main {
                      color: $color-pink;
                    }
                  }
                }
              }
            }
          }
        }
      }
      &-bridge {
        width: 160px;
        text-align: center;
        margin-top: 200px;
        position: relative;
        &-text {
          font-size: 14px;
          line-height: 1.6;
          margin-bottom: 20px;
        }
        &-arrow {
          svg {
            width: 105px;
            height: 48px;
            fill: #000;
            display: block;
            margin: 0 auto;
          }
        }
      }
    }
    &-analysis {
      margin-bottom: 60px;
      &-inner {
        margin: 0 auto;
        svg {
          width: 700px;
          height: 423px;
          display: block;
          margin: 0 auto;
          text {
            font-size: 24px;
            font-family: $font-gothic;
          }
        }
      }
    }
  }
  &-features {
    &-inner {
      width: $width-page-pc;
      margin: 0 auto;
    }
    &-list {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      &-item {
        width: 414px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        &-img {
          margin-bottom: 30px;
          .icon {
            height: 130px; 
          }
        }
        &-text {
          font-size: 24px;
          line-height: 1.4;
          text-align: center;
        }
      }
    }
  }
  &-detail {
    margin-bottom: 200px;
    &-inner {
      width: $width-page-pc;
      margin: 0 auto;
    }
    &-list {
      display: flex;
      justify-content: space-between;
      &-wrapper {

      }
      &-item {
        width: 350px;
        &-header {
          display: flex;
          align-items: center;
          font-size: 20px;
          line-height: 1.4;
          margin-bottom: 20px;
          .icon {
            width: 50px;
            height: 50px;
            margin-right: 10px;
          }
        }
        &-text {
          font-size: 16px;
          line-height: 2;
          margin-bottom: 15px;
        }
        &-list {
          margin-bottom: 15px;
          &-item {
            font-size: 16px;
            line-height: 2;
            margin-left: 1em;
            text-indent: -1em;
            &::before {
              content: "・";
            }
          }
        }
        &-period {
          display: flex;
          align-items: center;
          &-label {
            color: #FFF;
            background: #000;
            font-size: 14px;
            line-height: 30px;
            padding: 0 10px;
            margin: 0 15px 0 5px;
          }
          &-value {
            font-size: 16px;
            line-height: 1.6;
          }
        }
      }
    }
  }
  @include mq() {
    &-header {
      margin: 60px auto;
      .en {
        font-size: 28px;
        margin-bottom: 15px;
      }
      .ja {
        font-size: 36px;
      }
    }
    &-section {
      &-header {
        margin: 0 20px 40px;
        font-size: 24px;
        &-text {
          font-size: 14px;
          margin: 0 20px 30px;
        }
      }
    }
    &-index {
      &-wrapper {
        display: block;
        margin-top: 0;
        margin-bottom: 60px;
      }
      &-block {
        width: auto;
        margin-bottom: 60px;
        &:last-child {
          margin-bottom: 0;
        }
        &-row1 {
          margin: 0 20px 20px;
        }
        &-row2 {
          padding: 20px;
        }
        &-title {
          font-size: 20px;
          &-wrapper {
            margin-bottom: 30px;
          }
        }
        &-img {
          margin-bottom: 30px;
          .icon {
            display: block;
            margin: 0 auto;
            width: 50vw;
            height: 50vw;
          }
        }
        &-content {
          font-size: 14px;
        }
        &-list {
          &-item {
            font-size: 16px;
            line-height: 2;
            margin-bottom: 1.6px;
            padding-left: 20px;
            position: relative;
            &:last-child {
              margin-bottom: 0;
            }
          }
        }
        &-here {
          top: -40px;
          left: -80px;
          font-size: 18px;
          width: 72px;
          height: 72px;
        }
      }
    }
    &-contact {
      margin-bottom: 60px;
      a {
        height: 60vw;
        margin-bottom: 0;
      }
      &-header {
        font-size: 16px;
        margin-bottom: 10px;
      }
      &-main {
        font-size: 48px;
      }
    }
    &-catch {
      margin: 0 20px 80px;
      &-header {
        font-size: 24px;
        margin: 0 -10px 20px;
        white-space: nowrap;
      }
      &-text {
        font-size: 14px;
      }
    }
    &-strategy {
      &-header {
        margin-bottom: 60px;
        &-inner {
          padding: 20px 10px;
        }
        &-title {
          font-size: 26px;
          line-height: 1.2;
          margin-bottom: 20px;
        }
        &-text {
          font-size: 14px;
        }
      }
      &-section {
        margin-bottom: 60px;
        .service {
          &-features {
            &-list {
              justify-content: space-between;
              flex-wrap: nowrap;
              &-item {
                width: 28vw;
                margin: 0;
                &:first-child {
                  margin: 0;
                }
                &-text {
                  font-size: 11px;
                }
              }
            }
          }
        }
      }
      &-flow {
        .service-section-header {
          margin-bottom: 30px;
        }
        &-inner {
          margin: 0 20px;
        }
        svg {
          width: 100%;
          height: auto;
        }
      }
    }
    &-creative {
      &-text {
        font-size: 14px;
      }
      &-section {
        margin-bottom: 60px;
      }
      &-figure {
        &-inner {
          display: block;
          width: auto;
          margin: 0 20px;
        }
        &-block {
          text-align: center;
          &-label {
            font-size: 20px;
            margin-bottom: 20px;
          }
          &-general {
            width: 70%;
            margin: 0 auto 40px;
            .service-creative-figure-block {
              &-img {
                svg {
                  width: auto;
                  height: auto;
                }
                img {
                  width: 70%;
                }
              }
            }
          }
          &-tryangle {
            width: 100%;
            .service-creative-figure-block {
              &-img {
                svg {
                  width: auto;
                  height: auto;
                }
                img {
                  width: 70%;
                }
              }
            }
          }
          &-img {
            display: block;
            height: auto;
          }
        }
      }
      &-policy {
        &-inner {
          width: auto;
          margin: 0 20px;
        }
        &-list {
          display: block;
          &-item {
            width: auto;
            margin: 0 auto 60px;
            &-title {
              font-size: 20px;
              margin-bottom: 20px;
            }
            &-text {
              font-size: 14px;
            }
          }
        }
      }
      &-works {
        &-wrapper {
          display: block;
        }
        &-list {
          padding: 0;
          &-item {
            width: 50%;
            &:first-child {
              width: 100%;
              position: relative;
              top: 0;
              left: 0;
            }
          }
        }


      &-block {
          &.is_list {
            width: auto;
            .service-creative-works {
              &-list {
                &-item {
                  width: calc(50% - 20px);
                  &-text {
                    font-size: 14px;
                    margin-left: 10px;
                  }
                }
              }
            }
          }
          &.is_panel {
            width: auto;
            .service-creative-works {
              &-list {
                &-item {
                  width: 50%;
                }
              }
            }
          }
        }
      }
      &-system {
        &-inner {
          width: auto;
        }
        &-list {
          display: flex;
          align-items: center;
          margin-right: -10px;
          &-wrapper {
            padding: 30px 20px;
          }
          &-item {
            width: calc(33.3% - 10px);
            margin: 0 10px 20px 0;
            flex-shrink: 0;
            img {
              width: 80%;
            }
          }
          &-note {
            font-size: 14px;
            margin-bottom: 0;
          }
        }
        &-note {
          margin: 20px 20px 0;
          &-list {
            &-item {
              font-size: 14px;
            }
          }
        }
      }
    }
    &-operation {
      &-compare {
        margin-bottom: 80px;
        &-inner {
          display: block;
          width: auto;
          margin: 0 20px;
        }
        &-block {
          &-wrapper {
            width: auto;
          }
          &-title {
            font-size: 14px;
            margin-bottom: 20px;
          }
          &-feature {
            padding: 10px;
            &-list {
              &-item {
                height: 110px;
                padding: 10px;
                &-text {
                  &-sub {
                    font-size: 12px;
                  }
                  &-main {
                    font-size: 16px;
                  }
                }
              }
            }
          }
          &-bottom-text {
            font-size: 16px;
          }
        }
        &-bridge {
          display: flex;
          justify-content: center;
          align-items: center;
          margin: 20px auto;
          padding-right: 90px;
          &-text {
            font-size: 12px;
            margin-bottom: 5px;
          }
          &-arrow {
            svg {
              width: 60px;
              height: 60px;
              transform: rotate(90deg);
            }
          }
        }
      }
      &-analysis {
        &-inner {
          margin: 0 20px;
          svg {
            width: 100%;
            height: 50vw;
          }
        }
      }
    }
  
    &-features {
      &-inner {
        width: auto;
      }
      &-list {
        justify-content: center;
        flex-wrap: wrap;
        &-item {
          width: 44vw;
          margin: 0 5px;
          &-img {
            margin-bottom: 10px;
            .icon {
              height: 22vw; 
            }
          }
          &-text {
            font-size: 12px;
            letter-spacing: -0.1em;
          }
          &:first-child {
            margin: 0 30vw 20px;
          }
        }
      }
    }
    &-detail {
      margin-bottom: 60px;
      &-inner {
        width: auto;
        margin: 0 20px;
      }
      &-list {
        display: block;
        &-item {
          width: auto;
          padding-bottom: 20px;
          margin-bottom: 20px;
          border-bottom: 1px solid #CCC;
          &-header {
            font-size: 18px;
            margin-bottom: 10px;
            .icon {
              width: 30px;
              height: 30px;
            }
          }
          &-text {
            font-size: 14px;
            margin-bottom: 10px;
          }
          &-list {
            margin-bottom: 10px;
            &-item {
              font-size: 14px;
            }
          }
          &-period {
            &-label {
              font-size: 12px;
              line-height: 24px;
              margin: 0 10px 0 5px;
            }
            &-value {
              font-size: 14px;
            }
          }
        }
      }
    }
  }
}
