.about {
  &-section {
    &-catch {
      font-size: 54px;
      font-family: $font-mincho;
      font-weight: 600;
      line-height: 1.4;
      text-align: center;
      letter-spacing: 0.1em;
      margin-bottom: 60px;
    }
    &-content {
      &-header {
        font-size: 20px;
        line-height: 1.6;
        text-align: center;
        margin-bottom: 20px;
      }
      &-text {
        font-size: 16px;
        line-height: 1.6;
        text-align: center;
      }
    }
  }
  &-intro {
    margin-bottom: 100px;
    text-align: center;
    &-inner {
      width: $width-contents-pc;
      max-width: calc(100% - 60px);
      margin: 0 auto;
    }
    &-header {
      font-size: 54px;
      font-family: $font-mincho;
      font-weight: 600;
      line-height: 1.6;
      letter-spacing: 0.1em;
      margin-bottom: 30px;
    }
    &-text {
      font-size: 16px;
      line-height: 2;
      p {
        margin-bottom: 1em;
        &:last-child {
          margin-bottom: 0;
        }
      }
    }
    &-tryangle {
      display: flex;
      justify-content: center;
      margin-bottom: 30px;
      &-item {
        display: flex;
        flex-direction: column;
        align-items: center;
      }
      &-try {
        display: flex;
        align-items: center;
        background: #000;
        color: #FFF;
        font-size: 45px;
        height: 48px;
        line-height: 1;
        padding: 0 10px 4px;
        margin-bottom: 10px;
      }
      &-text {
        font-size: 16px;
        line-height: 1.6;
      }
      &-plus {
        width: 12px;
        height: 12px;
        margin: 20px 10px;
        font-size: 12px;
        font-weight: 600;
        position: relative;
        &::before, &::after {
          content: "";
          display: block;
          position: absolute;
        }
        &::before {
          width: 100%;
          top: 5px;
          left: 0;
          border-top: 2px solid #000;
        }
        &::after {
          height: 100%;
          top: 0;
          left: 5px;
          border-left: 1px solid #000;
        }
      }
    }
  }
  &-value {
    margin-bottom: 120px;
    &-inner {
      width: $width-contents-pc;
      max-width: calc(100% - 60px);
      margin: 0 auto;
    }
    &-img {
      svg {
        display: block;
        width: 718px;
        margin: 0 auto;
        text {
          font-family: $font-gothic;
          font-weight: 500;
        }
        text.label {
          font-size: 14px;
          font-family: $font-en;
          letter-spacing: 0.05em;
        }
        text.text {
          font-size: 20px;
        }
        text.list {
          font-size: 13px;
        }
      }
    }
  }
  &-mission {
    margin-bottom: 100px;
    &-inner {
      width: $width-contents-pc;
      max-width: calc(100% - 60px);
      margin: 0 auto;
    }
    &-list {
      &-wrapper {
        display: flex;
        justify-content: center;
      }
      &-item {
        display: flex;
        align-items: center;
        margin-bottom: 20px;
        &-num {
          width: 138px;
          height: 138px;
          border: 1px solid #707070;
          border-radius: 50%;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          text-align: center;
          font-family: $font-en;
          margin-right: 30px;
          flex-shrink: 0;
          .mission {
            font-size: 12px;
          }
          .num {
            font-size: 60px;
            font-weight: 600;
          }
        }
        &-text {
          font-size: 20px;
          line-height: 1.6;
        }
      }
    }
  }
  &-philosophy {
    margin-bottom: 100px;
    &-inner {
      width: $width-contents-pc;
      max-width: calc(100% - 60px);
      margin: 0 auto;
    }
    
  }
  &-credo {
    margin-bottom: 100px;
    &-inner {
      width: 1162px;
      max-width: calc(100% - 60px);
      margin: 0 auto;
    }
    &-list {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      &-item {
        width: calc(25% - 10px);
        border-radius: 50%;
        padding: 30px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
        background: #FFF;
        margin: 0 5px 10px;
        box-sizing: border-box;
        &-header {
          font-size: 20px;
          line-height: 1.6;
          margin-bottom: 10px;
        }
        &-text {
          font-size: 14px;
          line-height: 1.6;
          text-align: left;
        }
      }
    }
  }
  @include mq(lg) {
    &-credo {
      &-list {
        &-item {
          padding: 20px;
          &-header {
            font-size: 18px;
          }
          &-text {
            font-size: 13px;
          }
        }
      }
    }
  }
  @include mq() {
    &-section {
      &-catch {
        font-size: 28px;
        letter-spacing: 0;
        margin-bottom: 40px;
      }
      &-content {
        &-header {
          font-size: 18px;
          margin-bottom: 10px;
        }
        &-text {
          font-size: 14px;
          text-align: left;
        }
      }
    }
    &-intro {
      margin-bottom: 60px;
      &-inner {
        width: auto;
        max-width: 100%;
        margin: 0 20px;
      }
      &-header {
        font-size: 28px;
        letter-spacing: 0;
        margin-bottom: 20px;
      }
      &-text {
        font-size: 14px;
        text-align: left;
      }
      &-tryangle {
        &-try {
          font-size: 32px;
          height: 40px;
          margin-bottom: 5px;
        }
        &-text {
          font-size: 14px;
        }
      }
    }
    &-value {
      margin-bottom: 60px;
      &-inner {
        width: auto;
        max-width: 100%;
        margin: 0 20px;
      }
      &-img {
        svg {
          width: 100%;
        }
      }
    }
    &-mission {
      margin-bottom: 60px;
      &-inner {
        width: auto;
        max-width: 100%;
        margin: 0 20px;
      }
      &-list {
        &-item {
          &-num {
            width: 20vw;
            height: 20vw;
            margin-right: 10px;
            .mission {
              font-size: 11px;
            }
            .num {
              font-size: 40px;
            }
          }
          &-text {
            font-size: 14px;
          }
        }
      }
    }
    &-philosophy {
      margin-bottom: 60px;
      &-inner {
        width: auto;
        max-width: 100%;
        margin: 0 20px;
      }
    }
    &-credo {
      margin-bottom: 60px;
      &-inner {
        width: auto;
        max-width: 100%;
        margin: 0 10px;
      }
      &-list {
        margin: 0;
        &-item {
          width: calc(33.3% - 10px);
          padding: 10px;
          &-header {
            font-size: 16px;
            margin-bottom: 5px;
          }
          &-text {
            font-size: 12px;
          }
        }
      }
    }
  }
  @include mq(smd) {
    &-credo {
      &-list {
        &-item {
          width: calc(50% - 10px);
        }
      }
    }
  }
}
