.column {
  &-content{
    display: flex;
    // flex-wrap: wrap;
    width: $width-page-pc;
    max-width: calc(100% - 60px);
    margin: 0 auto;
    &-top{
      padding-top: 120px;
    }
    &-sidebar{
      margin-right: 60px;
      margin-bottom: 100px;
      // width: 250px;
      width: 20%;
      // flex-shrink: 0;
      &-title{
        margin-bottom: 36px;
        font-size: 20px;
        border-bottom: 1px solid #ccc;
        padding-bottom: 16px;
        letter-spacing: 0.8px;
        &:nth-of-type(2){
          border-bottom: none;
          margin-bottom: 15px;
        }
      }
      &-list{
        margin-bottom: 36px;
        border-bottom: 1px solid #ccc;
        padding-bottom: 16px;
        &-item{
          margin: 0 0 30px 20px;
          &-current{
            list-style: disc;
            font-weight: 600;
          }
        }
      }
      &-tags{
        display: flex;
        flex-wrap: wrap;
        margin-top: 10px;
        &-list{
          border-radius: 4px;
          border: 1px solid #BFBFBF;
          padding: 5px 10px;
          margin: 4px;
          font-size: 12px;
          background: #fff;
          a:hover{
            text-decoration: underline !important;
          }
          &-current{
            background: #000;
            a{
              color: #FFF;
            }
          }
        }
      }
    }
    &-details{
      width: 960px;
      margin: 0 auto;
    }
    &-marker{
      background: linear-gradient(transparent 0%, #FFFF00 0%);
    }
  }
  &-list {
    // display: flex;
    // flex-wrap: wrap;
    margin-bottom: 40px;
    &-header {
      width: $width-page-pc;
      max-width: calc(100% - 60px);
      display: flex;
      justify-content: flex-end;
      margin: 0 auto 30px;
    }
    &-wrapper {
      // width: 1028px;
      // max-width: calc(100% - 60px);
      // margin: 0 auto;
    }
    &-item {
      width: 100%;
      // margin-bottom: 50px;
      &-wrapper{
        display: flex;
        // flex-wrap: wrap;
        & > :first-child{
          min-width: 50%;
          max-width: 50%;
          margin-right: 40px;
        }
      }
      a {
        &:hover {
          text-decoration: none;
          img {
            opacity: 0.8;
          }
        }
      }
      &-img {
        // margin-bottom: 40px;
        margin-bottom: 20px;
        border: 1px solid lighten(#EEEDE6, 0.5);
        // flex-shrink: 0;
        // margin-right: 40px;
        // max-width: 426px;
        // width: 480px;
        max-width: 100%;
        // min-width: 280px;
        img {
          width: 100%;
        }
      }
      &-content {
        // margin-right: 50px;
        margin-right: 0;
        // max-width: 492px;
        max-width: 50%;
      }
      &-header {
        color: #888;
        font-size: 14px;
        line-height: 2;
        margin-bottom: 5px;
      }
      &-text {
        &-title{
          font-size: 16px;
          // line-height: 2;
          line-height: 1.5;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          overflow: hidden;
        }
        &-content{
          font-size: 14px;
          line-height: 1.7;
          margin-top: 10px;
          display: -webkit-box;
          -webkit-line-clamp: 5;
          -webkit-box-orient: vertical;
          overflow: hidden;
        }
      }
    }
  }
  &-detail {
    &-wrapper {
      width: $width-contents-pc;
      max-width: calc(100% - 60px);
      margin: 0 auto;
      padding-top: 120px;
    }
    &-header {
      margin-bottom: 40px;
      &-top {
        color: #888;
        font-size: 14px;
        // margin-bottom: 30px;
        margin-bottom: 20px;
      }
      &-title {
        font-size: 48px;
        line-height: 1.25;
      }
    }
    &-contents {
      margin-bottom: 150px;
      &-wrapper {
        line-height: 2;
        font-size: 18px;
        h2 {
          font-size: 36px;
          margin: 40px 0 60px;
          font-size: 32px;
          line-height: 1.625;
          padding-bottom: 48px;
          border-bottom: 1px dotted #ccc;
        }
        h3 {
          font-size: 28px;
          line-height: 1.625;
          margin: 60px 0 20px;
        }
        h4 {
          font-size: 24px;
          line-height: 1.625;
          margin: 20px 0 30px;
        }
        h5 {
          font-size: 22px;
          line-height: 1.625;
          margin: 10px 0 20px;
        }
        h6 {
          font-size: 20px;
          line-height: 1.625;
          margin: 10px 0;
        }
        p {
          font-size: 18px;
          line-height: 2;
          margin-bottom: 30px;
        }
        a {
          text-decoration: underline;
          &:hover {
            text-decoration: none;
          }
        }
        ul {
          font-size: 18px;
          line-height: 2;
          margin-bottom: 2em;
          li {
            margin-left: 1em;
            text-indent: -1em;
            &::before {
              content: "・";
            }
          }
        }
        .wp-caption{
          margin: 60px 0;
        }
        .wp-caption-text{
          text-align: right;
          max-width: 960px;
          margin-top: 20px;
          font-size: 14px;
          line-height: 1.6;
        }
        .wp-block-buttons {
          margin: 60px auto;
          display: flex;
          justify-content: center;
          position: relative;
          .wp-block-button__link {
            display: inline-flex;
            justify-content: center;
            align-items: center;
            font-family: $font-en;
            font-weight: $font-weight-light;
            position: relative;
            transition: .3s;
            cursor: pointer;
            border-radius: 0;
            color: #FFF;
            background: #000;
            min-width: 400px;
            height: 110px;
            font-size: 28px;
            text-decoration: none !important;
            &:hover {
              color: #AF9E5B;
            }
          }
        }
        .wp-block-image {
          display: flex;
          justify-content: center;
          &-inner{
            // margin: 60px 0 0;
            margin: 0;
          }
          img {
            display: block;
            margin: 0 auto;
            border: 1px solid lighten(#EEEDE6, 0.5);
          }
          figcaption {
            font-size: 16px;
            line-height: 1.6;
            text-align: right;
          }
          &.size {
            &-full {
              .wp-block-image {
                &-inner {
                  width: 100%;
                }
              }
              img {
                width: 100%;
              }
            }
            &-large {
              .wp-block-image {
                &-inner {
                  width: 100%;
                }
              }
              img {
                width: 100%;
              }
            }
            &-medium {
              img {
                max-width: 100%;
              }
            }
            &-thumbnail {
              img {
                max-width: 100%;
              }
            }
            &-custom{
              .wp-block-image-inner {
                margin: 0;
              }
              &-w600{
                img{
                  width: 600px;
                  margin: 0 auto;
                }
              }
              &-w700{
                img{
                  width: 700px;
                  margin: 0 auto;
                }
              }
              &-w750{
                img{
                  width: 750px;
                  margin: 0 auto;
                }
              }
              &-w800{
                img{
                  width: 800px;
                  margin: 0 auto;
                }
              }
            }
          }
        }
        .wp-block-quote {
          padding: 30px 60px;
          position: relative;
          &::before, &::after {
            content: "”";
            display: block;
            position: absolute;
            color: #888;
            font-size: 72px;
          }
          &::before {
            top: -24px;
            left: 0;
            transform: rotate(180deg);
          }
          &::after {
            bottom: -24px;
            right: 0;
          }
          cite {
            display: block;
            font-size: 16px;
            line-height: 2;
            margin-top: 1em;
          }
        }
      }
      &-tags{
        padding-top: 30px;
        margin-top: 60px;
        border-top: 1px dotted #CCC;
      }
    }
    &-section {
      &-header {
        font-size: 32px;
        line-height: 1.6;
        padding-bottom: 48px;
        margin-bottom: 60px;
        border-bottom: 1px dotted #CCC;
      }
      &-block {
        &-header {
          font-size: 24px;
          line-height: 1.6;
          margin: 60px 0 20px;
        }
      }
    }
    &-img {
      margin: 60px 0;
      img {
        display: block;
        max-width: 100%;
        margin: 0 auto;
        border: 1px solid lighten(#EEEDE6, 0.5);
      }
      &-caption {
        margin-top: 30px;
        font-size: 14px;
        line-height: 1.6;
        text-align: right;
      }
    }
    &-related-post {
      width: $width-page-pc;
      max-width: calc(100% - 60px);
      margin: 0 auto;
      &-list{
        display: flex;
        flex-wrap: wrap;
        &-item {
          width: 33.3%;
          margin-bottom: 50px;
          a {
            &:hover {
              text-decoration: none;
              img {
                opacity: 0.8;
              }
            }
          }
          &-img {
            margin-bottom: 30px;
            border: 1px solid lighten(#EEEDE6, 0.5);
            img {
              width: 100%;
            }
          }
          &-content {
            margin-right: 50px;
          }
          &-header {
            color: #888;
            font-size: 14px;
            line-height: 2;
            margin-bottom: 5px;
          }
          &-text {
            font-size: 16px;
            line-height: 2;
          }
          &-category{
            a:hover{
              text-decoration: underline!important;
            }
          }
        }
      }
    }
  }
  @include mq() {
    &-content{
      max-width: calc(100% - 40px);
      width: 100%;
      // margin: 0 auto;
      margin: 60px auto 0;
      flex-wrap: wrap;
      flex-direction: column-reverse;
      &-top{
        padding-top: 60px;
      }
      &-sidebar{
        // width: 60%;
        // margin: 0 30px 60px 0;
        min-width: 175px;
        width: 100%;
        margin: 20px auto 60px;
      }
      &-details{
        width: 100%;
      }
      // @media (max-width: 620px) {
      // }
    }
    &-list {
      &-header {
        width: auto;
        max-width: 100%;
        margin: 0 20px 20px;
      }
      &-wrapper {
        width: auto;
        max-width: 100%;
      }
      &-item {
        width: 100%;
        margin: 0 auto 40px;
        &-wrapper{
          flex-wrap: wrap;
          & > :first-child{
            min-width: 100%;
            max-width: 100%;
            margin-right: 0;
          }
        }
        &-img {
          margin-right: 0;
          margin-bottom: 15px;
          width: 100%;
          max-width: 100%;
        }
        &-content {
          margin: 0;
          min-width: 100%;
        }
        &-header {
          font-size: 12px;
        }
        &-text {
          font-size: 14px;
          line-height: 1.6;
          overflow: inherit;
        }
      }
    }
    &-detail {
      &-wrapper {
        width: auto;
        max-width: 100%;
        margin: 0 20px;
        padding-top: 60px;
      }
      &-header {
        // margin-bottom: 40px;
        // &-top {
        //   margin-bottom: 30px;
        // }
        &-title {
          font-size: 24px;
        }
      }
      &-contents {
        margin-bottom: 60px;
        &-wrapper{
          font-size: 16px;
          h2{
            margin: 40px 0 30px;
            padding-bottom: 20px;
          }
          h3{
            margin: 40px 0 20px;
          }
          p{
            font-size: 16px;
          }
          ul{
            font-size: 16px;
          }
          .wp-caption{
            margin: 40px 0;
            max-width: 100%;
            &-text{
              margin-top: 0;
            }
          }
          .wp-block-buttons {
            margin: 30px 20px;
            .wp-block-button__link {
              min-width: 100%;
              height: 60px;
              font-size: 24px;
            }
          }
          .wp-block-image {
            &-inner{
              // margin: 40px auto 0;
              margin: 0;
              // max-width: calc(100% - 40px);
            }
            figcaption {
              font-size: 14px;
              margin: 0.5em 0 1em;
            }
            &.size {
              &-full {
                margin: 0 -20px;
                figcaption {
                  margin: 0.5em 0 1em;
                }
              }
              &-large {
                margin: 0 -20px;
                figcaption {
                  margin: 0.5em 0 1em;
                }
              }
              &-custom{
                &-w600{
                  img{
                    width: 100%;
                  }
                }
                &-w700{
                  img{
                    width: 100%;
                  }
                }
                &-w750{
                  img{
                    width: 100%;
                  }
                }
                &-w800{
                  img{
                    width: 100%;
                  }
                }
              }
            }
          }
          .wp-block-quote{
            padding: 30px;
            &::before, &::after {
              font-size: 48px;
            }
            &::before {
              top: -12px;
            }
            &::after {
              bottom: -12px;
            }
            cite {
              font-size: 13px;
            }
          }
        }
        &-tags{
          margin-top: 30px;
        }
      }
      &-section {
        &-header {
          font-size: 20px;
          padding-bottom: 30px;
          margin-bottom: 40px;
        }
        &-block {
          &-header {
            font-size: 18px;
            margin-bottom: 20px;
          }
        }
      }
      &-img {
        margin: 0 -20px 40px;
        &-caption {
          margin: 15px 20px 0;
          font-size: 12px;
        }
      }
      &-related-post {
        width: auto;
        max-width: 100%;
        &-list{
          &-item {
            width: 50%;
            margin-bottom: 30px;
            &-img {
              margin-bottom: 15px;
            }
            &-content {
              margin: 0 10px;
            }
            &-header {
              font-size: 12px;
            }
            &-text {
              font-size: 14px;
              line-height: 1.6;
              @include line-clamp(3);
            }
          }
        }
      }
    }
  }
}
