.top {
  &-mv {
    position: relative;
    height: 100vh;
    margin-bottom: 90px;
    &-inner {
      width: $width-page-pc;
      max-width: calc(100% - 60px);
      height: 100%;
      margin: 0 auto;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: flex-end;
      position: absolute;
      top: 0;
      right: 50%;
      transform: translateX(50%);
    }
    &-content {
      display: flex;
      flex-direction: column;
      margin-top: 15vw;
      margin-right: 6vw;
    }
    &-title {
      font-size: 54px;
      font-family: $font-mincho;
      font-weight: 600;
      margin-bottom: 1.8vw;
      opacity: 0;
      white-space: nowrap;
      span {
        opacity: 0;
        letter-spacing: 0.1em;
        transition: .5s;
      }
      &.is_initialized {
        opacity: 1;
      }
    }
    &-text {
      font-size: 20px;
      line-height: 1.8;
      opacity: 0;
      letter-spacing: 0.05em;
      transition: 2s;
      &.is_initialized {
        opacity: 1;
      }
    }
    &-logo {
      position: absolute;
      bottom: 0;
      left: 50%;
      width: 100%;
      transform: translateX(-50%);
      .try-logo {
        width: 100%;
        height: 10vw;
        opacity: 0;
        transition: 2s;
      }
      &.is_initialized {
        .try-logo {
          opacity: 1;
        }
      }
    }
    &-img {
      position: absolute;
      top: -30vw;
      left: -40vw;
      top: 0;
      left: 0;
      height: 100vh;
      #mv-try {
        width: 100vw;
        max-height: calc(100% + 30vw);
        .clip {
          fill: none;
        }
        .yellow {
          fill: $color-yellow;
          animation: yellow1 0.6s ease-out 0s both;
        }
        .blue {
          fill: $color-blue;
          &1 {
            animation: blue1 0.6s ease-out 1.2s both;
          }
          &2 {
            animation: blue2 0.1s ease-out 1.5s both;
          }
        }
        .pink {
          fill: $color-pink;
          animation: pink1 0.6s ease-out 0.6s both;
        }
        .logo {
          animation: logo 3s ease-out 3s both;
          transform: translate(5%, 9.6%);
          scale: 0.9;
        }
      }
    }
    &-canvas {
      width: 100%;
      height: 100%;
      position: relative;
      &-wrapper {
        position: absolute;
        top: -30vw;
        left: -40vw;
        width: calc(100vw * 0.956 + 44vw);
        height: calc(100% + 30vw);
        z-index: -1;
      }
    }
  }
  &-works {
    padding-bottom: 80px;
    &-list {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      &-wrapper {
        width: $width-page-pc;
        max-width: calc(100% - 60px);
        margin: 0 auto;
      }
      &-item {
        margin: 0 30px 30px;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 140px;
        height: 50px;
        a {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 100%;
          height: 100%;
        }
        img {
          width: 100%;
          height: 100%;
          object-fit: contain;
          margin: 0 auto;
        }
        &.shochiku {
          width: 180px;
        }
        &.drmartens {
          width: 100px;
        }
        &.ralphlauren {
          width: 200px;
        }
        &.rinavis {
          width: 180px;
        }
        &.allu {
          width: 120px;
        }
        &.clubco {
          width: 160px;
        }
      }
    }
  }
  &-info, &-news {
    padding-bottom: 120px;
    &-list {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      &-wrapper {
        width: $width-page-pc;
        max-width: calc(100% - 60px);
        min-height: 565px;
        margin: 0 auto;
        position: relative;
      }
      &-item {
        width: calc(50% - 15px);
        margin-bottom: 30px;
        a {
          display: flex;
          &:hover {
            text-decoration: none;
            img {
              opacity: 0.8;
            }
          }
        }
        &-img {
          width: 250px;
          margin-right: 20px;
          flex-shrink: 0;
          border: 1px solid lighten(#EEEDE6, 0.5);
          img {
            width: 100%;
          }
        }
        &-header {
          color: #888;
          font-size: 14px;
          line-height: 2;
          margin-bottom: 5px;
        }
        &-text {
          font-size: 16px;
          line-height: 2;
        }
        &:first-child {
          position: absolute;
          top: 0;
          left: 0;
          a {
            display: block;
          }
          .top-info-list-item, .top-news-list-item {
            &-img {
              width: 100%;
              margin: 0 0 30px;
            }
          }
        }
      }
    }
  }
  &-service {
    padding: 80px 0;
    &-banner {
      img {
        width: 100%;
      }
    }
  }
  @include mq(lg) {
    &-mv {
      &-content {
        margin-right: 0;
      }
      &-title {
        font-size: 5.4vw;
      }
      &-text {
        font-size: 1.8vw;
      }
    }
    &-info, &-news {
      padding-bottom: 100px;
      &-list {
        &-wrapper {
          width: auto;
          max-width: 100%;
          min-height: none;
          margin: 0 20px;
        }
        &-item {
          width: auto;
          margin-bottom: 20px;
          &-img {
            width: 40%;
            margin-right: 20px;
          }
          &-header {
            font-size: 12px;
          }
          &-text {
            font-size: 14px;
            line-height: 1.6;
          }
          &:first-child {
            position: relative;
            width: 100%;
            .top-info-list-item, .top-news-list-item {
              &-img {
                width: 100%;
                margin: 0 0 5px;
              }
            }
          }
        }
      }
    }
  }
  @include mq() {
    &-mv {
      margin-bottom: 60px;
      height: auto;
      &-inner {
        width: auto;
        max-width: 100%;
        height: auto;
        margin: 0 20px;
        display: block;
        position: relative;
        top: auto;
        right: auto;
        transform: translateX(0);
        padding-top: 46vw;
      }
      &-title {
        font-size: 6.6vw;
        margin-bottom: 2.8px;
      }
      &-text {
        font-size: 3.2vw;
      }
    }
    &-works {
      padding-bottom: 40px;
      &-list {
        margin-right: -20px;
        justify-content: flex-start;
        &-wrapper {
          width: auto;
          max-width: 100%;
          margin: 0 20px;
        }
        &-item {
          width: calc(33.3% - 20px) !important;
          height: 12vw;
          margin: 0 20px 20px 0;
          a {
            margin: 0 2vw;
          }
          &.drmartens {
            a {
              margin: 0 4vw;
            }
          }
        }
      }
    }
    &-service {
      padding: 40px 0;
    }
  }
  @include mq(smd) {
    &-mv {
      margin-bottom: 40px;
      &-title {
        font-size: 6.6vw;
      }
      &-text {
        font-size: 3.2vw;
      }
    }
  }
}
.page-top {
  .contents {
    padding-top: 0;
  }
}

@keyframes yellow1 {
  0% {
    transform: translate3d(-100%, 0, 0);
  }
  100% {
    transform: translate3d(0, 0, 0);
  }
}
@keyframes blue1 {
  0% {
    transform: translate3d(50%, -100%, 0);
  }
  100% {
    transform: translate3d(0, 0, 0);
  }
}
@keyframes blue2 {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes pink1 {
  0% {
    transform: translate3d(50%, 100%, 0);
  }
  99% {
    transform: translate3d(0, 0, 0);
  }
  100% {
    transform: translate3d(-13%, -26%, 0);
  }
}
@keyframes logo {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}



