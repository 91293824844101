.sidemenu {
  position: fixed;
  top: 0;
  right: 0;
  width: $width-sidemenu;
  height: 100vh;
  overflow-y: auto;
  background: #FFF;
  transition: .3s;
  transform: translateX(100%);
  &-wrapper {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 200;
  }
  &-overlay {
    display: none;
    width: 100vw;
    height: 100vh;
    background: rgba(0,0,0,0.8);
  }
  &-inner {
    padding: 30px 30px 40px;
  }
  &-logo {
    margin-bottom: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    .try-shape {
      width: 34px;
      height: 29px;
      margin-right: 6px;
    }
    .try-logo {
      width: 150px;
      height: 17px;
    }
  }
  &-list {
    &-item {
      font-size: 18px;
      font-family: $font-en;
      font-weight: $font-weight-semi-bold;
      a {
        display: block;
        &:hover {
          text-decoration: none;
        }
      }
      & > a {
        padding: 10px 0;
      }
      &-title {
        a {
        padding: 10px 0;
        }
      }

      &-list {
        display: block;
        margin: 0 0 20px 20px;
        &-item {
          font-size: 13px;
          font-family: $font-gothic;
          font-weight: $font-weight-medium;
          line-height: 2;
          a {
            display: block;
            padding: 5px 0;
          }
          &-title {
            .js_toggle-trigger {
              &::before, &::after {
                display: block;
              }
            }
          }
          &-list {
            margin-bottom: 20px;
            &.js_toggle-content {
              display: none;
            }
          }
        }
      }
    }
  }
  &-btns {
    margin-top: 20px;
    .btn {
      width: 100%;
    }
  }
  &-close {
    position: fixed;
    top: 15px;
    left: 30px;
    transition: .3s;
    transform: translateX(-50px);
    .icon {
      width: 18px;
      height: 18px;
      fill: #FFF;
    }
  }
}
.is_menu-opened {
  position: fixed;
  .sidemenu {
    transform: translateX(0);
    &-overlay {
      display: block;
    }
    &-close {
      transform: translateX(0);
    }
  }
}
