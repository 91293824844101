.member {
  &-header {
    margin: 110px auto 0;
    text-align: center;
    .en {
      font-size: 50px;
      font-family: $font-en;
      margin-bottom: 20px;
    }
    .ja {
      font-size: 64px;
      font-weight: 600;
    }
  }
  &-links{
    margin: 40px auto 60px;
    display: grid;
    justify-content: center;
    grid-auto-flow: column;
    gap: 20px;
    text-align: center;
    &-item{
      &-a{
        font-family: $font-en;
        font-weight: 700;
        font-size: 18px;
      }
      .active{
        pointer-events: none !important;
      }
    }
  }
  &-outline {
    line-height: 2;
    margin-bottom: 100px;
    &-section{
      &-inner {
        width: $width-contents-pc;
        max-width: calc(100% - 60px);
        margin: 0 auto;
        display: flex;
        justify-content: space-between;
        &-img{
          width: 400px;
        }
        &-description{
          width: 520px;
          margin: -10px 0 0 40px;
          &-details{
            &-edu{
              margin: 30px 0 40px;
            }
            &-name{
              font-size: 24px;
              font-weight: bold;
            }
          }
          &-txt{
            margin-top: 30px;
          }
        }
      }
    }
    &-section02 {
      &-inner {
        margin-top: 100px;
        padding-top: 70px;
        border-top: 1px solid #ccc;
      }
    }
  }
  @include mq(lg) {
  }
  @include mq() {
    &-header {
      margin: 60px auto 0;
      .en {
        font-size: 28px;
        margin-bottom: 15px;
      }
      .ja {
        font-size: 36px;
      }
    }
    &-links{
      grid-template-columns: 1fr 1fr;
      grid-template-rows: 1fr 1fr;
      align-items: center;
      gap: 30px;
      width: fit-content;
      &-item{
        &-a{
          font-size: 16px;
        }
        .active{
          pointer-events: none !important;
        }
      }
    }
    &-outline{
      margin-bottom: 60px;
      &-section{
        &-inner {
          width: auto;
          max-width: 100%;
          margin: 0 20px;
          flex-direction: column;
          &-img{
            width: calc(100% - 60px);
            margin: 0 auto;
          }
          &-description{
            width: 100%;
            margin: 30px 0 0;
            &-details{
              &-edu{
                margin: 20px 0 30px;
              }
              &-name{
                font-size: 20px;
                font-weight: bold;
              }
            }
            &-txt{
              margin-top: 30px;
            }
          }
        }
      }
      &-section02 {
        &-inner {
          margin-top: 60px;
          padding-top: 40px;
        }
      }
    }
  }
  @include mq(smd) {
  }
}
