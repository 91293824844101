@charset "utf-8";

// #Media Query
//
// 使用例
// .foo {
//   color: blue;
//   @include mq() { // 引数を省略（初期値はmdの768px）
//     color: yellow;
//   }
//   @include mq(lg) { // 引数を個別に指定
//     color: red;
//   }
// }
// ==========================================================================
// $breakpoint-sm: $width-min-page !default;
// $breakpoint-md: $width-middle-page !default;
// $breakpoint-lg: $width-page-max !default;
$breakpoints: (
  'xsm': 'screen and (max-width: 320px)',
  'sm': 'screen and (max-width: 480px)',
  'smd': 'screen and (max-width: 640px)',
  'md': 'screen and (max-width: 768px)',
  'mlg': 'screen and (max-width: 900px)',
  'lg': 'screen and (max-width: 1024px)',
  'xlg': 'screen and (max-width: 1100px)',
) !default;
@mixin mq($breakpoint: md) {
  @media #{map-get($breakpoints, $breakpoint)} {
    @content;
  }
}

// Presentation:
// ロールオーバーをまとめて出力
//
// Example:
// @include hover {
//   color: #999;
// }
// ==========================================================================
@mixin hover {
  &:hover, &:active, &:focus {
    @content;
  }
}


// Presentation:
// 矢印アイコン生成
//
// $icon-position  : 矢印の位置（top/right/bottom/left）
// $icon-size      : 矢印のサイズ
// $icon-color     : 矢印の色
// $icon-direction : 矢印の方向（top/right/bottom/left）
// $padding        : 親要素のpadding
// $pseudo-element : 疑似要素指定（初期値：before［省略可］）
//
// Example:
// @include icon-arrow('right', '7px', $color-black, 'bottom', '18px');
// ==========================================================================
@mixin icon-arrow($icon-position, $icon-size, $icon-color, $icon-direction, $padding, $pseudo-element: 'before') {
  @if $icon-position == 'top' {
    padding-top: #{$padding};
  } @else if $icon-position == 'right' {
    padding-right: #{$padding};
  } @else if $icon-position == 'bottom' {
    padding-bottom: #{$padding};
  } @else if $icon-position == 'left' {
    padding-left: #{$padding};
  }
  position: relative;
  display: inline-block;
  &:#{$pseudo-element} {
    content: '';
    width: #{$icon-size};
    height: #{$icon-size};
    border-bottom: 1px solid #{$icon-color};
    border-left: 1px solid #{$icon-color};
    margin: (-#{$icon-size}) 0 0;
    position: absolute;
    top: 50%;
    #{$icon-position}: 2px;
    display: block;
    @if $icon-direction == 'top' {
      transform: rotate(135deg);
    } @else if $icon-direction == 'right' {
      transform: rotate(-135deg);
    } @else if $icon-direction == 'bottom' {
      transform: rotate(-45deg);
    } @else if $icon-direction == 'left' {
      transform: rotate(45deg);
    }
  }
}


// #margin , padding
//
// Example:
// @include spacing(#{$i*5});
// ==========================================================================
@mixin spacing($size) {
  $type: 'u_p';
  $prop: 'padding';
  @for $i from 1 through 2 {
    @if $i > 1 {
      $type: 'u_m';
      $prop: 'margin';
    }
    .#{$type}t-#{$size} { #{$prop}-top:    #{$size}px !important; }
    .#{$type}r-#{$size} { #{$prop}-right:  #{$size}px !important; }
    .#{$type}b-#{$size} { #{$prop}-bottom: #{$size}px !important; }
    .#{$type}l-#{$size} { #{$prop}-left:   #{$size}px !important; }
  }
}


// Presentation:
// 行制限
//
// Example:
// @include line-clamp(3);
// ==========================================================================
@mixin line-clamp($lines) {
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: $lines;
  overflow: hidden;
}


// Presentation:
// 複数行制限（PC用）
//
// Example:
// @include line-clamp-pc(3, 5em);
// ==========================================================================
@mixin line-clamp-pc($lines, $height) {
  @if $lines == 1 {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  } @else {
    max-height: $height;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: $lines;
    overflow: hidden;
  }
}


// #transform
// ==========================================================================
// Browser Prefixes
@mixin transform($transforms) {
  -webkit-transform: $transforms;
  // -ms-transform: $transforms;
  transform: $transforms;
}

// Rotate
@mixin rotate ($deg) {
  @include transform(rotate(#{$deg}deg));
}

// Scale
@mixin scale($scale) {
  @include transform(scale($scale));
}

// Translate
@mixin translate ($x, $y) {
  @include transform(translate($x, $y));
}

// Skew
@mixin skew ($x, $y) {
  @include transform(skew(#{$x}deg, #{$y}deg));
}

// Transform Origin
@mixin transform-origin ($origin) {
  -webkit-transform-origin: $origin;
  // -ms-transform-origin: $origin;
  transform-origin: $origin;
}


// #decimal point
// 小数の桁数と丸め方を制御する
//
// Example:
// @include round-decimal($number);
// @include ceil-decimal($number);
// @include floor-decimal($number);
// ==========================================================================
// Round (四捨五入)
@function round-decimal($number, $digits: 0) {
  @return to-fixed($number, $digits, 'round');
}

// Ceil (切り上げ)
@function ceil-decimal($number, $digits: 0) {
  @return to-fixed($number, $digits, 'ceil');
}

// Floor (切り捨て)
@function floor-decimal($number, $digits: 0) {
  @return to-fixed($number, $digits, 'floor');
}

@function to-fixed ($number, $digits: 0, $round: 'round') {
  $n: 1;
  // $number must be a number
  @if type-of($number) != number {
    @warn '#{ $number } is not a number.';
    @return $number;
  }
  // $digits must be a unitless number
  @if type-of($digits) != number {
    @warn '#{ $digits } is not a number.';
    @return $number;
  } @else if not unitless($digits) {
    @warn '#{ $digits } has a unit.';
    @return $number;
  }
  @for $i from 1 through $digits {
    $n: $n * 10;
  }
  @if $round == 'round' {
    @return round($number * $n) / $n;
  } @else if $round == 'ceil' {
    @return ceil($number * $n) / $n;
  } @else if $round == 'floor' {
    @return floor($number * $n) / $n;
  } @else {
    @warn '#{ $round } is undefined keyword.';
    @return $number;
  }
}