#bg {
  position: absolute;
  top: 0;
  left: 50%;
  width: 131.8%;
  height: 100%;
  transform: translateX(-50%);
  z-index: -1;
  opacity: 0;
  transition: 3s;
  svg {
    width : 100%;
    height : 100%;
    position: absolute;
    &.reverse {
      transform: rotate(180deg);
    }
    polygon {
      transition: 1s;
    }
  }
  &.is_initialized {
    opacity: 1;
  }
}
