//--------採用情報
.page-recruit{
  line-height: 2;
  .recruit{
    &-outline{
      width: $width-contents-pc;
      max-width: calc(100% - 60px);
      margin: 0 auto;
      margin-bottom: 120px;
      a{
        &:hover{
          text-decoration: none;
        }
      }
      @include mq() {
        width: auto;
        max-width: 100%;
        margin: 0 20px;
      }
      &-top{
        &-staff{
          border-top: 1px solid #ccc;
          .js_toggle-trigger {
            display: block;
            // opacity: 1;
            // cursor: default;
            position: relative;
            &::before, &::after {
              display: block;
            }
          }
          &-title{
            font-size: 24px;
            margin-top: 55px;
            margin-bottom: 10px;
          }
          &-description{
            width: 90%;
            font-size: 16px;
            margin-bottom: 55px;
          }
        }
      }
      &-details{
        &-txt{
          padding: 30px;
        }
        &-title{
          padding: 10px 0;
          border-bottom: 1px dotted #ccc;
          font-size: 20px;
          // margin-bottom: 20px;
        }
        .table{
          dt::before{
            content:"■ ";
          }
          li::before{
            content: "・";
          }
        }
        &-head{
          font-weight: 700;
        }
        &-head2{
          font-weight: 700;
          margin-top: 40px;
        }
        &-content{
          margin-bottom: 10px;
        }
      }
      h3{
        font-size: 1.125em;
      }
      &-analyst-staff{
        .js_toggle-trigger {
          display: block;
          // opacity: 1;
          // cursor: default;
          position: relative;
          &::before, &::after {
            display: block;
          }
        }
        border-top: #ccc 1px solid;
        border-bottom: #ccc 1px solid;
        &-title{
          font-size: 24px;
          margin-top: 55px;
          margin-bottom: 10px;
        }
        &-description{
          width: 90%;
          margin-bottom: 55px;
        }
      }
      &-engineer-front{
        .js_toggle-trigger {
          display: block;
          // opacity: 1;
          // cursor: default;
          position: relative;
          &::before, &::after {
            display: block;
          }
        }
        border-top: #ccc 1px solid;
        border-bottom: #ccc 1px solid;
        &-title{
          margin-top: 55px;
          margin-bottom: 10px;
          font-size: 24px;
        }
        &-description{
          width: 90%;
          margin-bottom: 55px;
        }
      }
      &-designer{
        &-staff{
          .js_toggle-trigger {
            display: block;
            // opacity: 0.7;
            // cursor: default;
            position: relative;
            &::before, &::after {
              display: block;
            }
          }
          border-bottom: #ccc 1px solid;
          &-title{
            margin-top: 55px;
            margin-bottom: 10px;
            font-size: 24px;
          }
          &-description{
            width: 90%;
            margin-bottom: 55px;
          }
        }
      }
      &-contact{
        &-description{
          margin-top: 60px;
        }
      }
      // .btn-size-l{
      //   width: 100%;
      // }
      .js_toggle-wrapper:hover{
        background: #fff;
      }
      .js_toggle-wrapper .js_toggle-trigger {
        padding: 30px;
        &::before{
          width: 30px;
          right: 30px;
        } 
        &::after {
          height: 30px;
          right: 44px;
          margin-top: -14px;
        }
      }
      @include mq() {
        .js_toggle-wrapper .js_toggle-trigger {
          padding: 20px;
          &::before{
            right: 0;
          } 
          &::after {
            right: 14px;
          }
        }
      }
    }
    @include mq() {
      &-details{
        .table{
          th{
            padding: 10px 0;
          }
          td{
            padding: 0 0 10px;
          }
        }
        dl{
          display: block;
          font-size: 14px;
          dt{
            width: auto;
          }
          dd{
            width: auto;
          }
        }
      }
      &-top-staff{
        &-description{
          width: 90%;
          font-size: 14px;
        }
      }
      &-engineer-front{
        &-description{
          width: 90%;
          font-size: 14px;
        }
      }
      &-designer{
        &-staff{
          &-description{
            width: 90%;
            font-size: 14px;
          }
        }
      }
      .btns{
        margin: 30px 0;
      }
    }
  }
  .pc-on{
    display: block;
    @include mq() {
      display: none;
    }
  }
  .sp-on{
    display: none;
    @include mq() {
      display: block;
    }
  }
}



//-----------------------------------採用情報フォーム
.page-recruit-entry {
  p{
    line-height: 1.7;
  }
  input[type="file"] {
    padding: 24px;
    width: 100%;
    max-width: calc(100% - 48px);
    border: 1px solid #ccc;
  }
  .borderBox {
    width: 610px;
    margin: 0 auto;
    border: 4px solid #f2f2f2;
    padding: 20px;
  }
  .btns {
    width: $width-form-pc;
    margin: 60px auto;
    .btn {
      width: 100%;
    }
    // .wpcf7-spinner {
    //   position: absolute;
    //   top: 0;
    //   left: -60px;
    // }
  }
  
  .privacy_info_box {
    margin-top: 20px;
    height: 15em;
    padding: 15px;
    border: 1px solid #ccc;
    overflow-y: scroll;
    p{
      margin-bottom: 20px;
    }
  }
  @include mq() {
    .borderBox {
      width: auto;
    }
    .btns {
      width: auto;
      margin: 30px 20px;
    }
    .icon-optional{
      word-break: keep-all;
    }
  }
}
.contact{
  &-description{
    font-size: 16px;
    line-height: 1.7;
    text-align: center;
    margin: 60px auto;
    @include mq() {
      font-size: 14px;
      margin: 30px 0;
      text-align: left;
    }
  }
}
