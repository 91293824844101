.footer {
  &-row1 {
    padding: 60px 0 80px;
    // border-top: 1px solid #CCC;
    &-inner {
      width: $width-page-pc;
      max-width: calc(100% - 60px);
      margin: 0 auto;
    }
  }
  &-logo {
    margin-bottom: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    a {
      &:hover {
        text-decoration: none;
      }
    }
    .try-shape {
      width: 42px;
      height: 36px;
      margin-right: 10px;
    }
    .try-logo {
      fill: #000;
      width: 190px;
      height: 22px;
    }
  }
  &-menu {
    &-list {
      display: flex;
      justify-content: space-between;
      &-item {
        font-size: 18px;
        font-family: $font-en;
        font-weight: $font-weight-semi-bold;
        margin: 0 10px;
        & > a {
          white-space: nowrap;
        }
        &-title {
          padding-bottom: 20px;
          white-space: nowrap;
          .js_toggle-trigger {
            display: block;
            opacity: 1;
            cursor: default;
            position: relative;
            &::before, &::after {
              display: none;
            }
          }
        }
        &-list {
          @media screen and (min-width: 769px) {
            &.js_toggle-content {
              display: block !important;
            }
          }
          &-item {
            font-size: 13px;
            font-family: $font-gothic;
            font-weight: $font-weight-medium;
            line-height: 2;
          }
        }
      }
      @media (max-width: 900px) {
        display: block;
        &-item {
          margin-bottom: 20px;
          &-title {
            .js_toggle-trigger {
              &::before, &::after {
                display: block;
              }
            }
          }
          &-list {
            margin: 0 0 20px 20px;
            &.js_toggle-content {
              display: none;
            }
            &-item {
              a {
                display: block;
                padding: 5px 0;
              }
            }
          }
          &.js_toggle-wrapper {
            margin-bottom: 0;
          }
        }
      }
    }
  }
  &-row2 {
    padding: 40px 0;
    background: #000;
    color: #FFF;
    a {
      color: #FFF;
      cursor: default;
    }
    &-inner {
      width: $width-page-pc;
      max-width: calc(100% - 60px);
      margin: 0 auto 30px;
      display: flex;
      justify-content: space-between;
    }
  }
  &-address {
    font-size: 12px;
    line-height: 2;
  }
  &-sns {
    &-list {
      display: flex;
      justify-content: flex-end;
      &-item {
        margin-left: 10px;
        a {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 30px;
          height: 30px;
          border: 2px solid #FFF;
          border-radius: 50%;
          cursor: pointer;
          .icon {
            fill: #FFF;
            width: 20px;
            height: 20px;
          }
        }
      }
    }
  }
  &-copyright {
    width: $width-page-pc;
    max-width: calc(100% - 60px);
    margin: 0 auto;
    font-size: 14px;
    font-family: $font-en;
    font-weight: $font-weight-regular;
  }
  @include mq() {
    &-row1 {
      padding: 30px 20px;
      &-inner {
        width: auto;
        max-width: 100%;
      }
    }
    &-logo {
      margin-bottom: 30px;
      .try-shape {
        width: 34px;
        height: 29px;
        margin-right: 6px;
      }
      .try-logo {
        width: 150px;
        height: 17px;
      }
    }
    &-menu {
      &-list {
        display: block;
        &-item {
          margin-bottom: 20px;
          &-title {
            .js_toggle-trigger {
              &::before, &::after {
                display: block;
              }
            }
          }
          &-list {
            margin: 0 0 20px 20px;
            &.js_toggle-content {
              display: none;
            }
            &-item {
              a {
                display: block;
                padding: 5px 0;
              }
            }
          }
          &.js_toggle-wrapper {
            margin-bottom: 0;
          }
        }
      }
    }
    &-row2 {
      padding: 30px 0;
      text-align: center;
      &-inner {
        width: auto;
        max-width: 100%;
        margin: 0 auto 30px;
        display: block;
      }
    }
    &-address {
      margin-bottom: 30px;
    }
    &-sns {
      &-list {
        justify-content: center;
        &-item {
          margin: 0 5px;
        }
      }
    }
    &-copyright {
      width: auto;
      max-width: 100%;
    }
  }
}
.btn-pagetop {
  position: fixed;
  bottom: 0;
  right: 0;
  transform: translateY(100%);
  opacity: 0;
  transition: .5s;
  z-index: 2;
  a {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 60px;
    height: 60px;
    background: #000;
    &::after {
      content: "";
      display: block;
      border: 5.5px solid transparent;
      border-bottom: 9px solid #FFF;
    }
    &:hover {
      opacity: 0.8;
    }
  }
  @include mq() {
    a {
      width: 40px;
      height: 40px;
    }
  }
}
.is_pagetop-visible {
  .btn-pagetop {
    transform: translateY(0);
    opacity: 1;
  }
  &.page-contact, &.page-recruit-entry {
    .btn-pagetop {
      transform: translateY(-80px);
    }
  }
}
