.contact {
  &-form {
    .wpcf7-list-item {
      margin: 0;
    }
    .wpcf7-not-valid-tip {
      font-size: 16px;
      line-height: 1.4;
      margin-top: 10px;
    }
    input[name="zip"] {
      & + .wpcf7-not-valid-tip {
        margin-top: -10px;
      }
    }
    // .btns {
    //   .wpcf7-spinner {
    //     position: absolute;
    //     top: 0;
    //     left: -60px;
    //   }
    // }
  }
}