.header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 75px;
  background: rgba(0,0,0,0.8);
  z-index: 100;
  &-inner {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100%;
  }
  &-logo {
    padding-left: 30px;
    a {
      display: flex;
      align-items: center;
    }
    .try-shape {
      width: 42px;
      height: 36px;
      margin-right: 10px;
    }
    .try-logo {
      fill: #FFF;
      width: 190px;
      height: 22px;
    }
  }
  &-nav {
    &-list {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      &-item {
        a {
          display: flex;
          align-items: center;
          color: #FFF;
          font-size: 18px;
          font-family: $font-en;
          font-weight: $font-weight-regular;
          text-decoration: none;
          height: 75px;
          padding: 0 20px;
          letter-spacing: 0.04em;
          &:hover {
            color: lighten(#AF9E5B, 10%);
            background: #000;
          }
        }
        &-contact {
          a {
            background: rgba(#AF9E5B, 0.8);
            &:hover {
              color: #FFF;
              background: darken(rgba(#AF9E5B, 0.8), 10%);
            }
          }
        }
      }
    }
  }
  &-menu {
    display: none;
  }
  @include mq(xlg) {
    &-nav {
      &-list {
        &-item {
          a {
            font-size: 16px;
            padding: 0 8px;
          }
          &-contact {
            margin-left: 5px;                    
            a {
              background: rgba(#AF9E5B, 0.8);
              padding: 0 13px 0 30px;
            }
          }
        }
      }
    }
  }
  @include mq(mlg) {
    height: auto;
    &-inner {
      height: 50px;
      padding: 0 10px;
    }
    &-logo {
      padding: 0;
      a {
        display: flex;
        align-items: center;
      }
      .try-shape {
        width: 34px;
        height: 29px;
        margin-right: 6px;
      }
      .try-logo {
        width: 150px;
        height: 17px;
      }
    }
    &-nav {
      display: none;
    }
    &-menu {
      display: block;
      a {
        display: flex;
        align-items: center;
        .icon {
          width: 24px;
          height: 22px;
          fill: #FFF;
        }
      }
    }
  }
}