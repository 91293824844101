@charset "utf-8";

// width
$width-page-pc: 1280px;
$width-contents-pc: 960px;
$width-form-pc: 650px;
$width-sidemenu: 300px;
$width-side-sp: 20px;

// color
$color-text: #000;

// font

// 游ゴシック
// $font-gothic: "Yu Gothic Medium", "游ゴシック Medium", YuGothic, "游ゴシック体", "Hiragino Kaku Gothic ProN","ヒラギノ角ゴ ProN W3",Meiryo,"メイリオ",Osaka,"MS PGothic",arial,helvetica,sans-serif;
// Zen Kaku Gothic New
$font-gothic: 'Zen Kaku Gothic New', "Yu Gothic Medium", "游ゴシック Medium", YuGothic, "游ゴシック体", "Hiragino Kaku Gothic ProN","ヒラギノ角ゴ ProN W3",Meiryo,"メイリオ",Osaka,"MS PGothic",arial,helvetica,sans-serif;

// 游明朝
$font-mincho: "游明朝体", "Yu Mincho", YuMincho, "ヒラギノ明朝 Pro", "Hiragino Mincho Pro", "MS P明朝", "MS PMincho", serif;


// futura
// $font-en: "futura-pt", "Yu Gothic Medium", "游ゴシック Medium", YuGothic, "游ゴシック体", "ヒラギノ角ゴ Pro W3", "メイリオ", sans-serif;
// Interstate
$font-en: "Interstate", 'Zen Kaku Gothic New', "Yu Gothic Medium", "游ゴシック Medium", YuGothic, "游ゴシック体", "ヒラギノ角ゴ Pro W3", "メイリオ", sans-serif;


$font-weight-bold: 700;
$font-weight-semi-bold: 600;
$font-weight-medium: 500;
$font-weight-regular: 400;
$font-weight-light: 300;

$color-text: #333;
$color-text2: #888;
$color-red: #e60012;
$color-grey: #f3f5f5;
$color-border-grey: #cdd1d2;
$color-blue: #009fe8;
$color-pink: #e3007f;
$color-yellow: #fff000;
