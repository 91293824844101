@charset "UTF-8";

html {
  overflow-y: scroll;
}
body {
  color: $color-text;
  font-family: $font-gothic;
  font-weight: 500;
  overflow-x: hidden;
  @include mq() {
    min-width: 0;
  }
}
// media query
.show-pc {
  display: inherit !important;
  @include mq() {
    display: none !important;
  }
}
.show-sp {
  display: none !important;
  @include mq() {
    display: inherit !important;
  }
}

a {
  color: $color-text;
  text-decoration: none;
  transition: all 0.3s;
  &:hover {
    text-decoration: underline;
  }
}
img{
  max-width: 100%;
}
.container {
  position: relative;
  width: 100%;
  overflow: hidden;
}
.contents {
  padding-top: 75px;
  position: relative;
  z-index: 1;
  @include mq() {
    padding-top: 50px;
  }
}

.main-header {
  margin: 110px auto;
  text-align: center;
  max-width: calc(100% - 60px);
  &-title {
    font-size: 100px;
    font-weight: 700;
    font-family: $font-en;
    line-height: 1.2;
    letter-spacing: 0.04em;
    margin-bottom: 48px;
  }
  &-subTitle{
    font-size: 60px;
    font-weight: 700;
    font-family: $font-en;
    line-height: 1.2;
    letter-spacing: 0.04em;
    margin-top: 48px;
  }
  &-comment {
    font-size: 32px;
    font-family: $font-mincho;
    font-weight: 600;
    line-height: 1.6;
    letter-spacing: 0.1em;
  }
  @include mq() {
    margin: 60px auto 40px;
    max-width: calc(100% - 40px);
    &-title {
      font-size: 54px;
      margin-bottom: 20px;
    }
    &-subTitle{
      font-size: 32px;
      margin-top: 20px;
    }
    &-comment {
      font-size: 18px;
    }
  }
}
.section-header {
  margin-bottom: 60px;
  text-align: center;
  &-title {
    font-size: 68px;
    font-weight: 700;
    font-family: $font-en;
    line-height: 1.2;
    letter-spacing: 0.04em;
    margin-bottom: 10px;
  }
  &-comment {
    font-size: 20px;
    font-family: $font-mincho;
    font-weight: 600;
    line-height: 1.6;
    letter-spacing: 0.1em;
  }
  @include mq() {
    margin-bottom: 40px;
    &-title {
      font-size: 36px;
      margin-bottom: 5px;
    }
    &-comment {
      font-size: 16px;
    }
  }
}
.section-title {
  font-size: 38px;
  font-weight: $font-weight-semi-bold;
  font-family: $font-en;
  letter-spacing: 0.04em;
  margin-bottom: 20px;
  @include mq() {
    font-size: 24px;
    margin: 0 20px 15px;;
  }
}

.section-block-header {
  font-size: 24px;
  font-weight: 600;
  line-height: 1.4;
  margin-bottom: 20px;
}

.p {
  font-size: 18px;
  line-height: 2;
  margin-bottom: 2em;
  @include mq() {
    font-size: 16px;
  }
}

.btns {
  margin: 60px auto;
  display: flex;
  justify-content: center;
  position: relative;
  @include mq() {
    margin: 30px 20px;
  }
  .customize-support & {
    padding-bottom: 40px;
  }

}
.wpcf7-spinner {
  position: absolute !important;
  top: auto !important;
  bottom: -40px !important;
  left: 50% !important;
  margin-left: -12px !important;
  @include mq() {
    bottom: 10px !important;
  }
}

.btn {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  font-family: $font-en;
  font-weight: 300;
  position: relative;
  transition: .3s;
  cursor: pointer;
  letter-spacing: 0.04em;
  &-main {
    color: #FFF;
    background: #000;
  }
  &-contact {
    color: #FFF;
    background: #AF9E5B;
    font-weight: $font-weight-semi-bold;
  }
  &-size {
    &-l {
      min-width: 400px;
      height: 110px;
      font-size: 28px;
    }
    &-s {
      height: 32px;
      padding: 0 10px;
      font-size: 14px;
    }
  }
  .icon {
    &-try {
      width: 20px;
      height: 20px;
      position: absolute;
      top: 50%;
      right: 50px;
      margin-top: -10px;
      transform: rotate(-30deg);
      transform-origin: 50% 12px;
      transition: .3s;
    }
  }
  &:hover {
    color: #AF9E5B;
    opacity: 1 !important;
    text-decoration: none !important;
    .icon {
      &-try {
        transform: rotate(0);
      }
    }
  }
  @include mq() {
    &-size {
      &-l {
        min-width: 100%;
        height: 60px;
        font-size: 24px;
      }
    }
  }
}
.js_submit-input {
  display: none;
}
.table {
  width: 100%;
  border-collapse: collapse;
  border-top: 1px dotted #CCC;
  tr {
    border-bottom: 1px dotted #CCC;
  }
  th {
    width: 170px;
    padding: 24px 0;
    font-size: 16px;
    font-weight: 600;
    line-height: 2;
    text-align: left;
  }
  td {
    width: calc(100% - 170px);
    padding: 24px 0;
    font-size: 16px;
    line-height: 2;
  }
  @include mq() {
    display: block;
    thead, tbody, tr, th, td {
      display: block;
    }
    th {
      width: auto;
      padding: 10px 20px;
      font-size: 14px;
      text-align: left;
    }
    td {
      width: auto;
      padding: 0 20px 10px;
      font-size: 14px;
    }
  }
}
.form-table {
  width: $width-form-pc;
  margin: 0 auto;
  dt {
    font-size: 20px;
    font-weight: 600;
    line-height: 1.4;
    // margin-bottom: 30px;
    margin-bottom: 15px;
    display: flex;
    align-items: center;
    .icon {
      display: inline-block;
      font-size: 12px;
      line-height: 28px;
      padding: 0 10px;
      margin-left: 30px;
      border: 1px solid $color-pink;
      &-required {
        color: #FFF;
        background: $color-pink;
      }
      &-optional {
        color: $color-pink;
        background: #FFF;
      }
    }
  }
  dd {
    margin-bottom: 60px;
  }
  & + .btns {
    width: $width-form-pc;
    margin: 0 auto 120px;
    .btn {
      width: 100%;
    }
  }
  .wpcf7-list-item {
    margin-bottom: 10px;
  }
  @include mq() {
    width: auto;
    margin: 0 20px;
    dt {
      font-size: 18px;
      margin-bottom: 20px;
    }
    dd {
      margin-bottom: 30px;
    }
    & + .btns {
      width: auto;
      margin: 0 20px 60px;
      & + div {
      }
    }
    .wpcf7-list-item {
      display: block;
      margin-bottom: 5px;
    }
  }
}
.wpcf7-response-output {
  width: $width-form-pc !important;
  margin: -60px auto 120px !important;
  line-height: 1.6;
  box-sizing: border-box;
  text-align: center;
  @include mq() {
    width: auto !important;
    margin: -40px 20px 60px !important;
  }
}

input {
  &[type="text"], &[type="password"], &[type="email"], &[type="number"], &[type="tel"] {
    border: 1px solid #CCC;
    background: #FFF;
    font-size: 16px;
    font-family: $font-gothic;
    padding: 0 24px;
    width: 100%;
    height: 66px;
    box-sizing: border-box;
  }
  &::placeholder {
    color: #AAA;
  }
  &[name="zip"] {
    width: 220px;
    margin-bottom: 15px;
  }
}
textarea {
  border: 1px solid #CCC;
  background: #FFF;
  font-size: 16px;
  font-family: $font-gothic;
  line-height: 2;
  padding: 24px;
  width: 100%;
  height: 220px;
  box-sizing: border-box;
  &::placeholder {
    color: #AAA;
  }
}
.js_toggle-wrapper {
  overflow: hidden;
  .js_toggle {
    &-trigger {
      position: relative;
      &::before, &::after {
        content: "";
        display: block;
        position: absolute;
        transition: .3s;
      }
      &::before {
        top: 50%;
        right: 0;
        width: 14px;
        border-top: 2px solid #000;
      }
      &::after {
        top: 50%;
        right: 6px;
        margin-top: -6px;
        height: 14px;
        border-left: 2px solid #000;
      }
    }
    &-content {
      display: none;
    }
  }
  &.is_opened {
    .js_toggle {
      &-trigger {
        &::after {
          transform: rotate(90deg);
        }
      }
    }
  }
}
.c_radio {
  display: none;
  & + label {
    padding-left: 24px;
    position: relative;
    margin-right: 24px;
    font-size: 16px;
    line-height: 1.6;
    &::before {
      content: "";
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      width: 18px;
      height: 18px;
      border: 1px solid #CCC;
      border-radius: 50%;
    }
  }
  &:checked + label {
    &::after {
      content: "";
      display: block;
      position: absolute;
      top: 5px;
      left: 5px;
      width: 10px;
      height: 10px;
      background: #000;
      border-radius: 50%;
    }
  }
}
.c_select {
  position: relative;
  select {
    height: 44px;
    background: #FFF;
    border: 1px solid #CCC;
    padding: 0 15px;
    font-size: 16px;
    font-family: $font-gothic;
    box-shadow: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    vertical-align: middle;
    padding-right: 35px;
    &:focus {
      outline: none;
    }
  }
  &::after {
    content: "";
    display: block;
    position: absolute;
    top: 50%;
    right: 10px;
    margin-top: -4px;
    border: 5px solid transparent;
    border-top: 5px solid $color-text;
    pointer-events: none;
  }
  @include mq() {
    select {
      height: 36px;
      font-size: 14px;
    }
  }
}
.c_file {
  display: flex;
  align-items: center;
  input[type="file"] {
    display: none;
  }
  .text {
    font-size: 14px;
    margin-left: 10px;
  }
}
.pager {
  &-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 80px;
  }
  &-btn{
    margin: 0 auto 40px;
    &-content{
      display: flex;
      justify-content: center;
      &-a {
        display: flex;
        justify-content: space-around;
        align-items: center;
        font-size: 20px;
        &-next{
          margin-left: auto;
        }
        &-txt{
          &-previous{
            margin-left: 10px;
          }
          &-next{
            margin-right: 10px;
          }
        }
        &:nth-of-type(2){
          margin-left: 40px;
        }
      }
    }
  }
  &-num {
    display: block;
    margin: 0 5px;
    &-ellipsis{
      width: 40px;
      height: 40px;
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 0;
      &:hover{
        background: none !important;
      }
    }
    a, >span {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 40px;
      height: 40px;
      color: $color-text;
      font-size: 20px;
      font-family: $font-en;
      font-weight: $font-weight-semi-bold;
      text-decoration: none;
      border: 1px solid #E5E5E5;
      @media (max-width:370px) {
        width: 32px;
        height: 32px;
        @media (max-width:330px){
          width: 28px;
          height: 28px;
        }
      }
    }
    &:hover{
      background: #E5E5E5;
    }
    &.is_current {
      &:hover{
        background: none;
      }
      a, >span {
        color: #FFF;
        background: $color-text;
      }
    }
    &-lessThan, &-greaterThan{
      border-top: 5px solid transparent;
      border-bottom: 5px solid transparent;
    }
    &-lessThan{
      border-right: 11px solid $color-text;
    }
    &-greaterThan{
      border-left: 11px solid $color-text;
    }
  }
  &-text {
    font-size: 14px;
    line-height: 1.4;
  }
  @include mq() {
    display: block;
    &-wrapper {
      justify-content: center;
      margin-bottom: 40px;
    }
  }
}
.breadcrumbs {
  width: $width-page-pc;
  max-width: calc(100% - 60px);
  margin: 20px auto 80px;
  &-list {
    display: flex;
    &-item {
      font-size: 14px;
      line-height: 1.6;
      a {
        color: #888;
      }
      &::after {
        content: "-";
        color: #888;
        margin: 0 5px;
      }
      &:last-child {
        &::after {
          display: none;
        }
      }
    }
  }
  @include mq() {
    width: auto;
    max-width: 100%;
    margin: 15px 20px;
    &-list {
      &-item {
        font-size: 12px;
      }
    }
  }
}
.not-found {
  width: $width-page-pc;
  margin: 0 auto 120px;
  text-align: center;
  @include mq() {
    width: auto;
    margin: 0 20px 40px;
  }
}