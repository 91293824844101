.company {
  &-outline {
    padding-top: 100px;
    &-inner {
      width: $width-contents-pc;
      max-width: calc(100% - 60px);
      margin: 0 auto;
    }
  }
  &-message {
    padding-top: 100px;
    &-inner {
      width: $width-contents-pc;
      max-width: calc(100% - 60px);
      margin: 0 auto;
    }
    &-img {
      margin-bottom: 60px;
      img {
        width: 100%;
      }
    }
    &-title {
      font-size: 54px;
      font-weight: 600;
      line-height: 1.6;
      margin-bottom: 60px;
    }
    &-text {
      font-size: 16px;
      line-height: 2;
      p {
        margin-bottom: 1em;
      }
    }
    &-sign {
      display: flex;
      justify-content: flex-end;
    }
  }
  &-access {
    padding: 100px 0;
    &-inner {
      width: $width-contents-pc;
      max-width: calc(100% - 60px);
      margin: 0 auto;
    }
    .map {
      width: 100%;
      height: 520px;
      margin-bottom: 20px;
    }
    &-link {
      margin-bottom: 20px;
      text-align: right;
      a {
        font-size: 16px;
        text-decoration: underline;
        &:hover {
          text-decoration: none;
        }
      }
    }
    &-info {
      font-size: 16px;
      line-height: 2;
      p {
        margin-bottom: 1em;
      }
    }
  }
  &-index {
    position: fixed;
    bottom: 10px;
    left: 0;
    z-index: 100;
    padding: 30px 30px 0 0;
    background: #FFF;
    box-shadow: 0 0 10px rgba(0,0,0,0.1);
    transition: .3s;
    &-list {
      &-item {
        margin-bottom: 30px;
        a {
          font-size: 18px;
          font-weight: $font-weight-semi-bold;
          font-family: $font-en;
          display: block;
          padding-left: 40px;
          position: relative;
        }
        &.is_current {
          a {
            &::before {
              content: "";
              display: block;
              position: absolute;
              top: 50%;
              left: 20px;
              margin-top: -4px;
              width: 8px;
              height: 8px;
              background: #000;
              border-radius: 50%;
            }
          }
        }
      }
    }
    &.is_hidden {
      transform: translateX(-100%);
    }
  }
  @include mq() {
    &-outline {
      padding-top: 40px;
      &-inner {
        width: auto;
        max-width: 100%;
      }
    }
    &-message {
      padding-top: 40px;
      &-inner {
        width: auto;
        max-width: 100%;
      }
      &-img {
        margin-bottom: 30px;
        img {
          width: 100%;
        }
      }
      &-title {
        font-size: 26px;
        margin: 0 20px 20px;
      }
      &-text {
        font-size: 14px;
        margin: 0 20px;
      }
      &-sign {
        margin: 0 20px;
        img {
          width: 120px;
        }
      }
    }
    &-access {
      padding: 40px 0 60px;
      &-inner {
        width: auto;
        max-width: 100%;
      }
      .map {
        width: 100%;
        height: 50vh;
      }
      &-link {
        margin: 0 20px;
        a {
          font-size: 14px;
        }
      }
      &-info {
        font-size: 14px;
        margin: 0 20px;
      }
    }
    &-index {
      position: fixed;
      top: auto;
      bottom: 0;
      background: #FFF;
      width: 100%;
      padding: 0;
      
      &-list {
        display: flex;
        &-item {
          width: 33.3%;
          margin-bottom: 0;
          a {
            padding: 10px 20px;
            text-align: center;
          }
          &.is_current {
            a {
              color: #FFF;
              background: rgba(0,0,0,0.8);
              &::before {
                display: none;
              }
            }
          }
        }
      }
      &.is_hidden {
        transform: translate(0, 100%);
      }
    }
          
  }
}
.page-company {
  .page-header {
    padding-bottom: 0;
  }
}