.info, .news {
  &-list {
    display: flex;
    flex-wrap: wrap;
    &-header {
      width: $width-page-pc;
      max-width: calc(100% - 60px);
      display: flex;
      justify-content: flex-end;
      margin: 0 auto 30px;
    }
    &-wrapper {
      width: $width-page-pc;
      max-width: calc(100% - 60px);
      margin: 0 auto;
    }
    &-item {
      width: 33.3%;
      margin-bottom: 50px;
      a {
        &:hover {
          text-decoration: none;
          img {
            opacity: 0.8;
          }
        }
      }
      &-img {
        // margin-bottom: 30px;
        margin-bottom: 20px;
        border: 1px solid lighten(#EEEDE6, 0.5);
        img {
          width: 100%;
        }
      }
      &-content {
        margin-right: 50px;
      }
      &-header {
        color: #888;
        font-size: 14px;
        line-height: 2;
        margin-bottom: 5px;
      }
      &-text {
        font-size: 16px;
        // line-height: 2;
        line-height: 1.5;
      }
    }
  }
  &-detail {
    &-wrapper {
      width: $width-contents-pc;
      max-width: calc(100% - 60px);
      margin: 0 auto;
      // padding-top: 120px;
    }
    &-header {
      // margin-bottom: 160px;
      margin-bottom: 40px;
      &-top {
        color: #888;
        font-size: 14px;
        // margin-bottom: 30px;
        margin-bottom: 20px;
      }
      &-title {
        font-size: 48px;
        line-height: 1.4;
      }
    }
    &-contents {
      margin-bottom: 150px;
      &-wrapper {
        h2 {
          font-size: 36px;
          line-height: 1.4;
          margin: 40px 0 60px;
        }
        h3 {
          font-size: 28px;
          line-height: 1.4;
          margin: 20px 0 40px;
        }
        h4 {
          font-size: 24px;
          line-height: 1.4;
          margin: 20px 0 30px;
        }
        h5 {
          font-size: 22px;
          line-height: 1.4;
          margin: 10px 0 20px;
        }
        h6 {
          font-size: 20px;
          line-height: 1.4;
          margin: 10px 0;
        }
        p {
          font-size: 18px;
          line-height: 2;
          margin-bottom: 2em;
          @include mq() {
            font-size: 16px;
          }
        }
        a {
          text-decoration: underline;
          &:hover {
            text-decoration: none;
          }
        }
        ul {
          font-size: 18px;
          line-height: 2;
          margin-bottom: 2em;
          @include mq() {
            font-size: 16px;
          }
          li {
            margin-left: 1em;
            text-indent: -1em;
            &::before {
              content: "・";
            }
          }
        }
        .wp-block-buttons {
          margin: 60px auto;
          display: flex;
          justify-content: center;
          position: relative;
          @include mq() {
            margin: 30px 20px;
          }
          .wp-block-button__link {
            display: inline-flex;
            justify-content: center;
            align-items: center;
            font-family: $font-en;
            font-weight: $font-weight-light;
            position: relative;
            transition: .3s;
            cursor: pointer;
            border-radius: 0;
            color: #FFF;
            background: #000;
            min-width: 400px;
            height: 110px;
            font-size: 28px;
            text-decoration: none !important;
            &:hover {
              color: #AF9E5B;
            }
            @include mq() {
              min-width: 100%;
              height: 60px;
              font-size: 24px;
            }
          }
        }
        .wp-block-image {
          display: flex;
          justify-content: center;
          img {
            display: block;
            margin: 0 auto;
            border: 1px solid lighten(#EEEDE6, 0.5);
          }
          figcaption {
            font-size: 16px;
            line-height: 1.6;
            text-align: right;
          }
          &.size {
            &-full {
              .wp-block-image {
                &-inner {
                  width: 100%;
                }
              }
              img {
                width: 100%;
              }
            }
            &-large {
              .wp-block-image {
                &-inner {
                  width: 100%;
                }
              }
              img {
                width: 100%;
              }
            }
            &-medium {
              img {
                max-width: 100%;
              }
            }
            &-thumbnail {
              img {
                max-width: 100%;
              }
            }
          }
          @include mq() {
            figcaption {
              font-size: 14px;
            }
            &.size {
              &-full {
                margin: 0 -20px;
                figcaption {
                  margin: 0.5em 20px 1em;
                }
              }
              &-large {
                margin: 0 -20px;
                figcaption {
                  margin: 0.5em 20px 1em;
                }
              }
            }
          }
        }
        .wp-block-quote {
          padding: 30px 60px;
          position: relative;
          &::before, &::after {
            content: "”";
            display: block;
            position: absolute;
            color: #888;
            font-size: 72px;
          }
          &::before {
            top: -24px;
            left: 0;
            transform: rotate(180deg);
          }
          &::after {
            bottom: -24px;
            right: 0;
          }
          cite {
            display: block;
            font-size: 16px;
            line-height: 2;
            margin-top: 1em;
          }
          @include mq() {
            padding: 30px;
            &::before, &::after {
              font-size: 48px;
            }
            &::before {
              top: -12px;
            }
            &::after {
              bottom: -12px;
            }
            cite {
              font-size: 13px;
            }
          }
        }
      }
    }
    &-section {
      &-header {
        font-size: 32px;
        line-height: 1.6;
        padding-bottom: 48px;
        margin-bottom: 60px;
        border-bottom: 1px dotted #CCC;
      }
      &-block {
        &-header {
          font-size: 24px;
          line-height: 1.6;
          margin-bottom: 30px;
        }
      }
    }
    &-img {
      margin-bottom: 150px;
      img {
        display: block;
        max-width: 100%;
        margin: 0 auto;
        border: 1px solid lighten(#EEEDE6, 0.5);
      }
      &-caption {
        margin-top: 30px;
        font-size: 14px;
        line-height: 1.6;
        text-align: right;
      }
    }
    &-related-post {
      width: $width-page-pc;
      max-width: calc(100% - 60px);
      margin: 0 auto;
    }
  }
  @include mq() {
    &-list {
      &-header {
        width: auto;
        max-width: 100%;
        margin: 0 20px 20px;
      }
      &-wrapper {
        width: auto;
        max-width: 100%;
      }
      &-item {
        width: 50%;
        margin-bottom: 30px;
        &-img {
          margin-bottom: 15px;
        }
        &-content {
          margin: 0 10px;
        }
        &-header {
          font-size: 12px;
        }
        &-text {
          font-size: 14px;
          line-height: 1.6;
          @include line-clamp(3);
        }
      }
    }
    &-detail {
      &-wrapper {
        width: auto;
        max-width: 100%;
        margin: 0 20px;
        padding-top: 60px;
      }
      &-header {
        margin-bottom: 60px;
        &-top {
          margin-bottom: 20px;
        }
        &-title {
          font-size: 24px;
        }
      }
      &-contents {
        margin-bottom: 60px;
      }
      &-section {
        &-header {
          font-size: 20px;
          padding-bottom: 30px;
          margin-bottom: 40px;
        }
        &-block {
          &-header {
            font-size: 18px;
            margin-bottom: 20px;
          }
        }
      }
      &-img {
        margin: 0 -20px 60px;
        &-caption {
          margin: 15px 20px 0;
          font-size: 12px;
        }
      }
      &-related-post {
        width: auto;
        max-width: 100%;
      }
    }
  }
}
